import Box from '@material-ui/core/Box';
import * as React from 'react';
import { Card } from '../gameLogic/Cards';
import { GameLayout, PromptText } from './components/GameLayout';
import { CardIcon, HandDisplay } from './components/HandDisplay';
import { ReplayLastTrickButton } from './components/ReplayLastTrickButton';
import { ThePlayDisplayProps } from './ThePlayDisplayDelegator';

export function ThePlayDisplayTrick(props: ThePlayDisplayProps): JSX.Element {
  const awaitedPosition = props.stateContext.awaitedPlayer;
  const awaitedPlayerName =
    props.gameConfig.playerFriendlyNames[awaitedPosition];
  const promptMessage =
    props.stateContext.awaitedPlayer === props.seatedAt
      ? "It's your turn. Click a card to play it."
      : `Waiting for ${awaitedPlayerName} to play a card…`;

  const playedYet = props.seatedAt
    ? !!props.stateContext.currentTrick[props.seatedAt]
    : false;

  const promptElement = playedYet ? (
    <PromptText>{promptMessage}</PromptText>
  ) : (
    <>
      <PromptText>{promptMessage}</PromptText>
      <Box mt={2}>
        <ReplayLastTrickButton />
      </Box>
    </>
  );

  return (
    <GameLayout
      colorMode="dark"
      playerFriendlyNames={props.gameConfig.playerFriendlyNames}
      playersSittingOut={props.stateContext.playersSittingOut}
      score={props.stateContext.score}
      trumpSuit={props.stateContext.trump}
      trickCount={props.stateContext.trickCount}
      seatedAt={props.seatedAt}
      awaitedPosition={awaitedPosition}
      renderPlayerCardContent={(position) => (
        <PlayedCard card={props.stateContext.currentTrick[position]} />
      )}
      promptMessage={promptElement}
      handsElement={
        <HandDisplay
          position={props.seatedAt}
          renderAsButtons={true}
          {...props}
        />
      }
      debugControls={<TrickDebugControls {...props} />}
    />
  );
}

export function PlayedCard(props: { card: Card | null }): JSX.Element | null {
  if (props.card) {
    return <CardIcon card={props.card} />;
  } else {
    return null;
  }
}

function TrickDebugControls(props: ThePlayDisplayProps): JSX.Element {
  const awaitedPlayer = props.stateContext.awaitedPlayer;

  return (
    <HandDisplay position={awaitedPlayer} renderAsButtons={true} {...props} />
  );
}
