import { StateSchema, EventObject, StateMachine } from 'xstate';
import { HydratedState } from './serializeAndHydrateState';

/**
 * Runs the given event through the machine's pure transition function to see if it will effect any
 * changes in the context or state value. This takes into account the machine's guards and actions,
 * unlike `state.handles(event)`, which looks only at the event type.
 */
export function willEventApply<
  C,
  SS extends StateSchema,
  E extends EventObject
>(
  machine: StateMachine<C, SS, E>,
  currentState: HydratedState<C, E, SS> | null | undefined,
  event: E
): boolean {
  try {
    return (
      machine.transition(currentState?.hydratedState, event).changed ?? false
    );
  } catch (e) {
    return false;
  }
}
